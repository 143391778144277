<template>
  <div>
    <van-nav-bar left-arrow :title="page.header" fixed @click-left="$router.push('/Home')"> </van-nav-bar>
    <div style="padding-top:46px;">
      <van-list v-model="loading" :finished="finished" finished-:text="$t('workFlow.没有更多了')" @load="onLoad">
        <ul class="workflow-container">
          <li v-for="(item, index) in list" :key="index" class="workflow-card" @click="toDetail(item)">
            <!-- 标题 -->
            <span style="font-size: 14px">{{ page.titleFunc(item) }}</span>
            <div class="tbm-subtitle">
              <!-- 提交人 -->
              <p>
                <label style="width: auto">{{ $t('workFlow.提交人') }}:</label>
                <span>{{ page.authorFunc(item) }}</span>
              </p>
              <p>
                <span>{{ page.timeFunc(item) }}</span>
              </p>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 30, // hjh_new 临时修改为30 之前为10
      list: [],
      isFirstNode: false,
      total: 0
    };
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
    page() {
      const pages = {
        waitfor: {
          // 页面标题
          header: '待办',
          // 查询接口
          queryUrl: '/microarch/activiti/task/toDoList/in/' + localStorage.getItem('usernames'),
          // 查询参数(固定参数, 其余页面参数放在data里)
          queryParams: {
            orderBy: 'createTime DESC',
            where: []
          },
          // 获取行标题方法
          titleFunc: row => row['businessInfo'],
          // 获取提交人方法
          authorFunc: row => row['startUser'],
          // 获取时间方法
          timeFunc: row => row['createTime']
        },
        transated: {
          header: '已办',
          queryUrl: '/microarch/activiti/task/haveDoneList/in/' + localStorage.getItem('usernames'),
          queryParams: {
            orderBy: 'endTime DESC',
            where: []
          },
          titleFunc: row => row['businessInfo'],
          authorFunc: row => row['startUserName'],
          timeFunc: row => row['endTime']
        },
        read: {
          header: '待阅',
          queryUrl: '/microarch/activiti/task/encyclicList/' + sessionStorage.getItem('userName'),
          queryParams: {
            orderBy: 'cdate DESC',
            where: [
              { field: 'speople', opt: 'like', value: sessionStorage.getItem('userName'), assemble: 'and' },
              { field: 'sdate', opt: 'null', assemble: 'and' }
            ]
          },
          titleFunc: row => row['processInstanceName'],
          authorFunc: row => row['cpeopleName'],
          timeFunc: row => row['cdate']
        },
        readed: {
          header: '已阅',
          queryUrl: '/microarch/activiti/task/encyclicReadedList/in/' + sessionStorage.getItem('userName'),
          queryParams: {
            orderBy: 'cdate DESC',
            where: [
              { field: 'speople', opt: 'like', value: sessionStorage.getItem('userName'), assemble: 'and' },
              { field: 'sdate', opt: 'notnull', assemble: 'and' }
            ]
          },
          titleFunc: row => row['processInstanceName'],
          authorFunc: row => row['cpeopleName'],
          timeFunc: row => row['cdate']
        }
      };
      return pages[this.type] || pages['waitfor'];
    }
  },
  methods: {
    onLoad() {
      this.$myHttp({
        method: 'POST',
        url: this.page.queryUrl,
        data: {
          ...this.page.queryParams,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.status !== 200) {
          this.loading = false;
          this.finished = true;
          return;
        }
        const rows = res.data.rows;
        this.list.push(...rows);
        this.loading = false;
        this.total += rows.length;
        this.pageNum++;
        if (res.data.total <= this.total) {
          this.finished = true;
        }
      });
    },
    toDetail(row) {
      this.isFirstNode = row.activityId === 'apply';
      let rows = JSON.stringify(row);
      if (row.configFormKey) {
        var formKey = row.configFormKey;
        if (row.configFormKey.indexOf('?') != -1) {
          formKey = formKey.split('?')[0];
          row.page = row.configFormKey.split('=')[1];
        }
        // return
        this.$router.push({
          name: formKey,
          params: {
            row: row,
            pageType: 'cust'
          },
          query: {
            businessKey: row.businessKey,
            type: this.type,
            row: rows,
            pageType: 'cust',
            t: new Date().getTime()
          }
        });
        return;
      }
      // 解析工单流程映射配置的URL 0 编辑页面 1 查看页面
      var editPage = row.businessUrl.split(',')[0];
      var viewPage = row.businessUrl.split(',')[1];
      if (this.isFirstNode && this.type == 'waitfor') {
        // 编辑
        if (editPage.indexOf('?') != -1) {
          editPage = editPage.split('?')[0];
          row.page = row.businessUrl.split(',')[0].split('=')[1];
        }
        this.$router.push({
          name: editPage,
          params: {
            row: row,
            pageType: 'edit'
          },
          query: {
            businessKey: row.businessKey,
            type: this.type,
            row: rows,
            pageType: 'edit',
            t: new Date().getTime()
          }
        });
      } else if (this.type == 'read') {
        this.$myHttp({
          method: 'get',
          url: '/microarch/activiti/task/commitRead/' + row.id,
          data: {}
        }).then(res => {
          // 解析工单流程映射配置的URL 0 编辑页面 1 查看页面
          if (viewPage.indexOf('?') != -1) {
            viewPage = viewPage.split('?')[0];
            row.page = row.businessUrl.split(',')[1].split('=')[1];
          }
          this.$router.push({
            name: viewPage,
            params: {
              row: row,
              pageType: 'view'
            },
            query: {
              businessKey: row.businessKey,
              type: this.type,
              row: rows,
              pageType: 'view',
              t: new Date().getTime()
            }
          });
        });
      } else {
        // 查看
        if (viewPage.indexOf('?') != -1) {
          viewPage = viewPage.split('?')[0];
          row.page = row.businessUrl.split(',')[1].split('=')[1];
        }
        this.$router.push({
          name: viewPage,
          params: {
            row: row,
            pageType: 'view'
          },
          query: {
            businessKey: row.businessKey,
            type: this.type,
            row: rows,
            pageType: 'view',
            t: new Date().getTime()
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.workflow-container {
  padding: 10px;
  background-color: #fff;
}
.workflow-card {
  font-size: 14px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
}
.tbm-subtitle {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #646566;
}
.workflow-container li {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
}
.workflow-container li > div:nth-child(2) p {
  display: flex;
  padding: 5px;
  padding-bottom: 0;
}
.workflow-container li > div:nth-child(2) p label {
  width: 75px;
  margin-right: 10px;
  color: #646566;
  flex-shrink: 0;
}
</style>
